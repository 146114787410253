.m-modal-purchase {
  .m-purchase-steps {
    margin-bottom: 20px;

    .card {
      border: 0;

      .card-header {
        background: #ffffff;
      }

      .card-block {
        padding: 20px 20px 0 20px;
      }
    }
  }
}
