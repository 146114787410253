//
//** Widget 19 Component
//

//== Component Base
@mixin m-build-component--widget19-base() {
  .m-widget19 {
    .m-widget19__pic {
      position: relative;

      > img {
        width: 100%;
      }

      .m-widget19__shadow {
        position: absolute;
        top: 70%;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .m-widget19__title {
        position: absolute;
        bottom: 0;
        display: block;
        z-index: 1;
        padding-left: array-get($m--portlet, layout, self, space);
        padding-bottom: array-get($m--portlet, layout, self, space) / 2;
      }
    }

    .m-widget19__content {
      margin-bottom: 1rem;


      .m-widget19__header {
        display: table;
        margin-top: 1.75rem;
        margin-bottom: 1.75rem;

        .m-widget19__user-img {
          display: table-cell;
          margin-top: 0.4rem;
          vertical-align: middle;

          .m-widget19__img {
            width: 3.2rem;
            border-radius: 50%;
          }
        }

        .m-widget19__info {
          display: table-cell;
          width: 100%;
          padding-left: 1rem;
          font-size: get-font-size(regular);
          font-weight: get-font-weight(regular);
          vertical-align: middle;
          line-height: 0;

          .m-widget19__username {
            display: inline-block;
            margin-bottom: 1.4rem;
            font-size: get-font-size(regular);
            font-weight: get-font-weight(bolder);
          }

          .m-widget19__time {
            display: inline-block;
            font-size: get-font-size(regular, '-');
            font-weight: get-font-weight(regular);
          }

        }

        .m-widget19__stats {
          display: table-cell;
          font-size: get-font-size(regular);
          font-weight: get-font-weight(bolder);
          vertical-align: middle;
          line-height: 0;
          text-align: center;

          .m-widget19__number {
            text-align: center;
            display: inline-block;
            margin-bottom: 1.3rem;
            font-size: get-font-size(regular, '++++');
            font-weight: get-font-weight(boldest);
          }

          .m-widget19__comment {
            display: inline-block;
            font-size: get-font-size(regular, '-');
            font-weight: get-font-weight(regular);
          }
        }
      }

      .m-widget19__body {
        font-size: get-font-size(regular);
        font-weight: get-font-weight(regular);
        text-align: justify;
      }
    }

    .m-widget19__action {
      margin-top: 1.5rem;
    }
  }
}

//== Component Skin
@mixin m-build-component--widget19-skin() {
  $skin: get-default-skin();

  .m-widget19 {
    .m-widget19__pic {
      .m-widget19__shadow {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, .1) 40%, rgba(0, 0, 0, .6) 90%) no-repeat scroll 0 0;
      }
    }

    .m-widget19__content {
      .m-widget19__title {
        color: get-color($skin, regular);
      }

      .m-widget19__time {
        color: get-color($skin, regular, '--');
      }

      .m-widget19__username {
        color: get-color($skin, regular);
      }

      .m-widget19__comment {
        color: get-color($skin, muted);
      }

      .m-widget19__body {
        color: get-color($skin, regular, '---');
      }
    }
  }
}

//== Build Component Base
@include m-build-component--widget19-base();

//== Build Component Skin
@include m-build-component--widget19-skin();
