//
//** Sticky Nav Component
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--nav-sticky-base($layout) {
  .m-nav-sticky {
    width: 40px;
    position: fixed;
    top: 35%;
    right: 0;
    list-style: none;
    padding: 5px 0;
    margin: 0;
    z-index: array-get($layout, self, z-index);

    @include rounded {
      @include border-radius(8px 0 0 8px);
    }

    .m-nav-sticky__item {
      margin: 0;
      padding: 5px;
      text-align: center;

      > a {
        > i {
          font-size: get-font-size(icon, '+');
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

//== Component Skin
@mixin m-build-component--nav-sticky-skin($layout, $skin) {
  @include component-skin(m-nav-sticky, $skin) {
    background: array-get($layout, bg-color);
    @include shadow(array-get($layout, box-shadow));

    .m-nav-sticky__item {
      > a {
        > i {
          color: get-color($skin, icon, '+');
        }

        &:hover {
          > i {
            color: get-brand-color('-')
          }
        }
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--nav-sticky-base(array-get($m--nav, sticky, layout));

//== Component Skin - Light
@include m-build-component--nav-sticky-skin(array-get($m--nav, sticky, skins, light), light);

//== Component Skin - Dark
@include m-build-component--nav-sticky-skin(array-get($m--nav, sticky, skins, dark), dark);
