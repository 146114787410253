//
//** Override The Base Framework Config For This Demo
//

//== The body element font settings
$m--root-font: (
        size: (
                desktop: 13px,
                tablet: 13px,
                mobile: 13px
        ),
        weight: 300,
        color: (
                light: #333333,
                dark: #efefef
        )
);

//== Font families
$m--font-families: (
        regular: 'Poppins',
        heading: 'Roboto'
);

//== Brand Color
$m--brand-color: #716aca;
$m--brand-inverse-color: #ffffff;
