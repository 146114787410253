//
//** Error 4 Snippet
//

//== Snippet Base
@mixin m-build-snippet--error-4-base() {
  .m-error-4 {
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    .m-error_container {

      .m-error_number {
        font-size: 15.7rem;
        margin-left: 14.3rem;
        margin-top: 11.4rem;
        font-weight: get-font-weight(boldest);
      }

      .m-error_title {
        margin: -70px 0 2% 14.3rem;
        font-size: 10rem;
        font-weight: get-font-weight(boldest);
      }

      .m-error_description {
        margin-left: 15rem;
        font-size: 2.5rem;
        margin: -70px 0 2% 14.8rem;
        font-weight: get-font-weight(boldest);
      }
    }
  }

  @include tablet {
    .m-error-4 {


      .m-error_container {
        .m-error_number {
          font-size: 12rem;
          margin-left: 7rem;
          margin-top: 8rem;
          font-weight: get-font-weight(boldest);
        }

        .m-error_title {
          margin: -40px 0 2% 7rem;
          font-size: 7rem;
          font-weight: get-font-weight(boldest);
        }

        .m-error_description {
          margin-left: 15rem;
          font-size: 2rem;
          margin: -40px 0 2% 7.3rem;
          font-weight: get-font-weight(boldest);
        }
      }
    }
  }

  @include mobile() {
    .m-error-4 {

      .m-error_container {
        text-align: center;

        .m-error_number {
          font-size: 9rem;
          margin: 4rem auto 0 auto;
        }

        .m-error_title {
          margin: 0.3rem auto;
          text-align: center;
          font-size: 5rem;
        }

        .m-error_description {
          text-align: center;
          font-size: 2rem;
          margin: 0.3rem auto;
          padding: 0 0.5rem 0 0.5rem;
        }
      }
    }
  }
}

//== Snippet Skin
@mixin m-build-snippet--error-4-skin() {
  $skin: get-default-skin();
  .m-error_container {

    .m-error_number {
      color: #84D49E;
    }

    .m-error_title {
      color: #84D49E;
    }

    .m-error_description {
      color: #CC6622;
    }
  }
}

//== Build Snippet Base
@include m-build-snippet--error-4-base();

//== Build Snippet Base
@include m-build-snippet--error-4-skin(); 
