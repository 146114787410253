//
//** Topbar
//

//== Topbar Base Mixin
@mixin m-build-topbar-nav-base($layout) {
  //== General mode
  .m-topbar {
    width: auto;
    height: 100%;
    float: right;
    padding: array-get($layout, default, self, padding, desktop);
    @include transition(all array-get($layout, default, self, transition-time, push) ease);

    &.m-topbar--pushed {
      @include transition(all array-get($layout, default, self, transition-time, push) ease);
    }

    .m-topbar__nav.m-nav {
      margin: array-get($layout, default, nav, self, margin, desktop);

      // nav item
      > .m-nav__item {
        padding: array-get($layout, default, nav, item, self, padding, desktop);

        > .m-nav__link {
          position: relative;
          margin: 0 auto;

          .m-nav__link-badge {
            left: 50%;
            margin-left: array-get($layout, default, nav, item, link, badge, margin-left, desktop);
            position: absolute;
            top: array-get($layout, default, nav, item, link, badge, top, desktop);
          }

          // nav item link's icon part
          .m-nav__link-icon {
            text-align: array-get($layout, default, nav, item, link, icon, align);
            line-height: 0;
            vertical-align: middle;
            padding: 0;

            > i {
              color: array-get($layout, default, nav, item, link, icon, font-color, default);
              font-size: array-get($layout, default, nav, item, link, icon, font-size);
            }

            i:before {
              padding: 1px;
            }

            &:hover {
              > i {
                color: array-get($layout, default, nav, item, link, icon, font-color, hover);
              }
            }
          }

          // username
          .m-topbar__username {
            display: table-cell;
            vertical-align: middle;
            line-height: 0;
            font-size: array-get($layout, default, nav, item, link, user, username, font-size);
            font-weight: array-get($layout, default, nav, item, link, user, username, font-weight);
            text-align: array-get($layout, default, nav, item, link, user, username, align);
            color: array-get($layout, default, nav, item, link, user, username, font-color, default);
            padding-right: 0.5rem;
          }

          // userpic
          .m-topbar__userpic {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            margin: 0 auto;

            img {
              display: block;
              vertical-align: middle;
              max-width: array-get($layout, default, nav, item, link, user, userpic, max-width, desktop) !important;
              margin: array-get($layout, default, nav, item, link, user, userpic, margin);
              border-radius: 50%;
              margin: 0 !important;
            }
          }
        }

        &.m-topbar__notifications.m-topbar__notifications--img {
          @include m-component--dropdown--set-arrow-color(array-get($layout, default, nav, dropdown, notifications, bg-color));
        }

        &.m-topbar__quick-actions.m-topbar__quick-actions--img {
          @include m-component--dropdown--set-arrow-color(array-get($layout, default, nav, dropdown, quick-actions, bg-color));
        }

        &.m-topbar__user-profile.m-topbar__user-profile--img {
          @include m-component--dropdown--set-arrow-color(array-get($layout, default, nav, dropdown, user-profile, bg-color));
        }

        // offcanvas dropdown mode
        &.m-dropdown.m-dropdown--offcanvas.m-dropdown--header-bg-fil.m-dropdown--arrow {
          @include m-component--dropdown--set-arrow-color(array-get($layout, default, self, bg-color), bottom);
        }
      }
    }
  }

  //== Minimal desktop
  @include minimal-desktop {
    .m-topbar {
      padding: array-get($layout, default, self, padding, min-desktop);

      .m-topbar__nav.m-nav {
        margin: array-get($layout, default, nav, self, margin, min-desktop);

        > .m-nav__item {
          padding: array-get($layout, default, nav, item, self, padding, min-desktop);
        }
      }
    }
  }

  //== Mobile & tablet mode
  @include tablet-and-mobile {
    .m-topbar {
      //z-index: 1;
      width: 100% !important;
      padding: array-get($layout, default, self, padding, mobile);
      transition: array-get($layout, toggle, transition);
      height: array-get($layout, toggle, height, mobile) !important;
      margin-top: -(array-get($layout, toggle, height, mobile));
      position: relative;
      background-color: array-get($layout, toggle, bg-color);
      box-shadow: array-get($layout, toggle, box-shadow);

      .m-topbar--on & {
        transition: array-get($layout, toggle, transition);
        margin-top: 0;
      }

      .m-header--fixed-mobile & {
        margin-top: 0;
        top: -(array-get($layout, toggle, height, mobile));

      }

      .m-header--fixed-mobile.m-topbar--on & {
        margin-top: 0;
        top: 0;
        transition: array-get($layout, toggle, transition);
      }

      .m-topbar__nav.m-nav {
        margin: array-get($layout, default, nav, self, margin, table-and-mobile);
        float: right;

        > .m-nav__item {
          padding: array-get($layout, default, nav, item, self, padding, tablet-and-mobile);

          > .m-nav__link {
            .m-nav__link-badge {
              margin-left: array-get($layout, default, nav, item, link, badge, margin-left, mobile);
              top: array-get($layout, default, nav, item, link, badge, top, mobile);
            }

            .m-topbar__userpic {
              img {
                max-width: array-get($layout, default, nav, item, link, user, userpic, max-width, mobile) !important;
              }
            }
          }
        }
      }
    }
  }

  //== Mobile mode
  @include mobile {
    .m-topbar {
      .m-topbar__nav.m-nav {
        > .m-nav__item.m-dropdown {
          position: static;
        }
      }
    }
  }
}


//== Build Topbar Base
@include m-build-topbar-nav-base(array-get($m-config-header, topbar));