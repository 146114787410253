//
//** Page Loader Config
//

$m--page-loader: (
  // layout
        layout: (
                self: (
                        zindex: 1000
                )
        )
);
