//
//** Navs Customization
// 

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--nav-base() {
  //== Base
  .nav {
    &.nav-pills,
    &.nav-tabs {
      margin-bottom: 20px;
    }

    &.nav-pills,
    &.nav-tabs {
      .nav-item {
        margin-left: 5px;

        &:first-child {
          margin-left: 0;
        }
      }

      .nav-link {
        font-weight: get-font-weight(bold);

        @include icons-attr(font-size, (fontawesome: 1.2rem, lineawesome: 1.4rem, metronic: 1.6rem));

        i {
          vertical-align: middle;
          line-height: 0;
          display: inline-block;
          margin-right: 0.5rem;
        }
      }
    }

    &.nav-pills {
      &.m-nav-pills--btn-pill {
        .m-tabs__link {
          @include border-radius(20px);
        }
      }

      &.m-nav-pills--btn-sm {
        .m-tabs__link {
          padding: 0.6em 1.4em;
          font-size: get-font-size(regular, '-');
        }
      }
    }
  }
}

//== Component Skin
@mixin m-build-component--nav-skin() {
  $skin: light;

  .nav {
    &.nav-pills,
    &.nav-tabs {
      .nav-link {
        color: get-color($skin, regular, '-');

        &.m-nav-link--icon {
          i {
            color: get-color($skin, regular, '-');
          }
        }

        &.disabled {
          color: get-color($skin, muted);

          i {
            color: get-color($skin, muted, '-');
          }
        }
      }
    }

    &.nav-pills {
      .nav-item.dropdown.show > .nav-link,
      .nav-link.active {
        color: $nav-pills-active-link-color;

        i {
          color: darken($nav-pills-active-link-color, 6%);
        }
      }
    }

    @each $name, $color in $m--state-colors {
      &.nav-pills.nav-pills--#{$name} {
        .nav-link {
          &.active {
            background: array-get($color, base);
            color: array-get($color, inverse);

            i {
              color: darken(array-get($color, inverse), 6%);
            }
          }
        }

        .nav-item.show {
          .nav-link {
            background: array-get($color, base);
            color: array-get($color, inverse);

            i {
              color: darken(array-get($color, inverse), 6%);
            }
          }
        }
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--nav-base();

//== Component Skin - Light
@include m-build-component--nav-skin();
