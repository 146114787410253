//
//** Pricing Tables 4 Snippet
//

//== Snippet Base
@mixin m-build-snippet--pricing-tables-base() {
  .m-pricing-table-4 {
    padding-bottom: 4rem;

    .m-pricing-table-4__top {
      background-color: #E0306E;

      .m-pricing-table-4__top-container {
        &.m-pricing-table-4__top-container--fixed {
          width: 80%;
          margin: 0 auto;

          .m-pricing-table-4__top-header {
            .m-pricing-table-4__top-title {
              padding-top: 5rem;
              margin-bottom: 5rem;
              text-align: center;

              > h1 {
                font-size: 2.3rem;
              }
            }
          }

          .m-pricing-table-4__top-body {
            margin-left: 14.3rem;

            .m-pricing-table-4__top-items {
              display: table;
              width: 100%;
              table-layout: fixed;
              box-shadow: 0 -1.3rem 1.07rem 0.71rem #D93371;

              .m-pricing-table-4__top-item {
                display: table-cell;
                text-align: center;
                border-right: 1px solid #e1f1ff;

                &:last-child {
                  border-right: none;
                }

                .m-pricing-table-4__icon {
                  > i {
                    font-size: 5rem;
                    margin-top: 3.6rem;
                  }
                }

                .m-pricing-table-4__subtitle {
                  font-size: 1.64rem;
                  margin-top: 2.14rem;
                }

                .m-pricing-table-4__features {
                  font-size: 0.9rem;
                  margin-top: 2.14rem;
                  margin-bottom: 1.43rem;
                  padding: 0 0.71rem 0 0.71rem;
                }

                .m-pricing-table-4__price {
                  font-size: 3.2rem;
                  font-weight: get-font-weight(boldest);
                }

                .m-pricing-table-4__label {
                  font-size: 1.64rem;
                  vertical-align: 43%;
                }

                .m-pricing-table-4__btn {
                  margin-top: 1.43rem;
                  margin-bottom: 3.6rem;

                  .btn {
                    padding: 0.8rem 3.6rem 0.8rem 3.6rem;
                  }
                }

                .m-pricing-table-4__top-items-mobile {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .m-pricing-table-4__bottom {
      .m-pricing-table-4__bottom-container {
        &.m-pricing-table-4__bottom-container--fixed {
          width: 80%;
          margin: 0 auto;

          .m-pricing-table-4__bottom-items {
            display: table;
            width: 100%;
            text-align: center;
            table-layout: fixed;

            .m-pricing-table-4__bottom-item {
              display: table-cell;
              padding: 1.43rem 0 1.43rem 0;
              font-weight: get-font-weight(boldest);

              &:first-child {
                width: 14.3rem;
                text-align: left;
                padding-left: 1.43rem;
                font-weight: get-font-weight(boldest);
              }
            }

            &:nth-child(odd) {
              background-color: #FAFBFF;
            }
          }
        }
      }
    }
  }


  @include minimal-desktop() {
    .m-pricing-table-4 {
      .m-pricing-table-4__top {
        .m-pricing-table-4__top-container {
          &.m-pricing-table-4__top-container--fixed {
            width: 90%;
            margin: 0 auto;

            .m-pricing-table-4__top-body {
              margin-left: 14.3rem;
            }
          }
        }
      }

      .m-pricing-table-4__bottom {
        .m-pricing-table-4__bottom-container {
          &.m-pricing-table-4__bottom-container--fixed {
            width: 90%;
            margin: 0 auto;
          }
        }
      }
    }
  }

  @include tablet() {
    .m-pricing-table-4 {
      .m-pricing-table-4__top {
        background: none;

        .m-pricing-table-4__top-container {
          &.m-pricing-table-4__top-container--fixed {
            width: 90%;
            margin: 0 auto;

            .m-pricing-table-4__top-header {
              background-color: #E0306E;
              padding-bottom: 21.43rem;
            }

            .m-pricing-table-4__top-body {
              width: 80%;
              margin: 0 auto;
              margin-top: -21.4rem;

              .m-pricing-table-4__top-items {
                box-shadow: none;

                .m-pricing-table-4__top-item {
                  display: block;
                  border-right: none;
                  padding-bottom: 3.6rem;

                  .m-pricing-table-4__features {
                    padding: 0 0.71rem 0 0.71rem;
                  }

                  .m-pricing-table-4__btn {
                    display: none;
                  }

                  .m-pricing-table-4__top-items-mobile {
                    display: block;
                    margin: 0 auto;

                    .m-pricing-table-4__top-item-mobile {
                      padding: 0.71rem 0 0.71rem 0;

                      > span {
                        &:first-child {
                          font-weight: get-font-weight(boldest);
                        }
                      }
                    }
                  }

                  .m-pricing-table-4__top-btn {
                    margin-top: 2.86rem;

                    .btn {
                      padding: 0.8rem 3.6rem 0.8rem 3.6rem;
                    }
                  }

                  border-bottom: 1px solid #e1f1ff;
                }
              }
            }
          }
        }
      }

      .m-pricing-table-4__bottom {
        .m-pricing-table-4__bottom-container {
          &.m-pricing-table-4__bottom-container--fixed {
            display: none;
          }
        }
      }
    }
  }

  @include mobile() {
    .m-pricing-table-4 {
      .m-pricing-table-4__top {
        background: none;

        .m-pricing-table-4__top-container {
          &.m-pricing-table-4__top-container--fixed {
            width: 100%;
            margin: 0 auto;

            .m-pricing-table-4__top-header {
              background-color: #E0306E;
              padding-bottom: 21.43rem;

              .m-pricing-table-4__top-title {
                > h1 {
                  font-size: 1.8rem;
                }
              }
            }

            .m-pricing-table-4__top-body {
              width: 80%;
              margin: 0 auto;
              margin-top: -21.43rem;

              .m-pricing-table-4__top-items {
                box-shadow: none;

                .m-pricing-table-4__top-item {
                  display: block;
                  border-right: none;
                  padding-bottom: 1rem;

                  .m-pricing-table-4__btn {
                    display: none;
                  }

                  .m-pricing-table-4__features {
                    padding: 0 0.71rem 0 0.71rem;
                  }

                  .m-pricing-table-4__top-items-mobile {
                    display: block;
                    margin: 2.14rem auto;

                    .m-pricing-table-4__top-item-mobile {
                      padding: 0.71rem 0 0.71rem 0;

                      > span {
                        &:first-child {
                          font-weight: get-font-weight(boldest);
                        }
                      }
                    }

                    .m-pricing-table-4__top-btn {
                      margin-top: 2.86rem;

                      .btn {
                        padding: 0.8rem 3.6rem 0.8rem 3.6rem;
                      }
                    }
                  }

                  border-bottom: 1px solid #e1f1ff;
                }
              }
            }
          }
        }
      }

      .m-pricing-table-4__bottom {
        .m-pricing-table-4__bottom-container {
          &.m-pricing-table-4__bottom-container--fixed {
            display: none;
          }
        }
      }
    }
  }
}

//== Snippet Skin
@mixin m-build-snippet--pricing-tables-skin() {
  $skin: get-default-skin();
  .m-pricing-table-4 {
    .m-pricing-table-4__top {
      .m-pricing-table-4__top-container {
        &.m-pricing-table-4__top-container--fixed {
          .m-pricing-table-4__top-body {
            .m-pricing-table-4__top-items {
              .m-pricing-table-4__top-item {
                background-color: white;

                .m-pricing-table-4__subtitle {
                  color: get-color($skin, regular, '-');
                }

                .m-pricing-table-4__features {
                  color: get-color($skin, regular, '---');
                }

                .m-pricing-table-4__price {
                  color: get-color($skin, regular, '-');
                }

                .m-pricing-table-4__label {
                  color: get-color($skin, regular, '----');
                }

                .m-pricing-table-4__top-items-mobile {
                  .m-pricing-table-4__top-item-mobile {
                    color: get-color($skin, regular, '----');

                    > span {
                      &:first-child {
                        color: get-color($skin, regular);
                      }
                    }

                    &:nth-child(odd) {
                      background-color: #FAFBFF;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .m-pricing-table-4__bottom {
      .m-pricing-table-4__bottom-container {
        &.m-pricing-table-4__bottom-container--fixed {
          .m-pricing-table-4__bottom-items {

            .m-pricing-table-4__bottom-item {
              color: get-color($skin, regular, '-----');

              &:first-child {
                color: #696B84;
              }
            }

            &:nth-child(odd) {
              background-color: #FAFBFF;
            }
          }
        }
      }
    }
  }
}

//== Build Snippet Base
@include m-build-snippet--pricing-tables-base();

//== Build Snippet - Skin
@include m-build-snippet--pricing-tables-skin();
