//
//** Switch Component
//

//==== Component Mixins ====//

/*
    <span class="m-switch">
            <input type="checkbox" checked="checked" name="">
            <span></span>
        </label>
    </span>
*/

//== Component Base
@mixin m-build-component--switch-base() {
  .m-switch {
    //== Base
    display: inline-block;
    font-size: 1rem;

    input:empty {
      margin-left: -999px;
      height: 0;
      width: 0;
      overflow: hidden;
      position: absolute;
      @include opacity(0);
    }

    input:empty ~ span {
      display: inline-block;
      position: relative;
      float: left;
      width: 1px;
      text-indent: 0;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    input:empty ~ span:before,
    input:empty ~ span:after {
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      content: ' ';
      -webkit-transition: all 100ms ease-in;
      transition: all 100ms ease-in;
    }

    //== State icons
    &.m-switch--icon {
      input:empty ~ span:after {
        @include la-icon-self('\f342');
      }

      input:checked ~ span:after {
        content: '\f17b';
      }
    }

    //== State icons
    &.m-switch--icon-check {
      input:checked ~ span:after {
        @include la-icon-self('\f17b');
      }
    }

    //== Default size
    & {
      $m-switch-margin: 2px 0;
      $m-switch-width: 54px;
      $m-switch-height: 30px;
      $m-switch-line-height: 26px;
      $m-switch-space: 3px;
      $m-switch-checked-tick-pos: 26px;
      $m-switch-icon-font-size: 0.9em;

      @include m-build-component--switch-size(
                      $m-switch-margin,
                      $m-switch-width,
                      $m-switch-height,
                      $m-switch-line-height,
                      $m-switch-space,
                      $m-switch-checked-tick-pos,
                      $m-switch-icon-font-size
      );
    }

    //== Large size
    &.m-switch--lg {
      $m-switch-margin: 2px 0;
      $m-switch-width: 72px;
      $m-switch-height: 40px;
      $m-switch-line-height: 34px;
      $m-switch-space: 3px;
      $m-switch-checked-tick-pos: 34px;
      $m-switch-icon-font-size: 1em;

      @include m-build-component--switch-size(
                      $m-switch-margin,
                      $m-switch-width,
                      $m-switch-height,
                      $m-switch-line-height,
                      $m-switch-space,
                      $m-switch-checked-tick-pos,
                      $m-switch-icon-font-size
      );
    }

    //== Small size
    &.m-switch--sm {
      $m-switch-margin: 2px 0;
      $m-switch-width: 38px;
      $m-switch-height: 24px;
      $m-switch-line-height: 20px;
      $m-switch-space: 2px;
      $m-switch-checked-tick-pos: 16px;
      $m-switch-icon-font-size: 0.8em;

      @include m-build-component--switch-size(
                      $m-switch-margin,
                      $m-switch-width,
                      $m-switch-height,
                      $m-switch-line-height,
                      $m-switch-space,
                      $m-switch-checked-tick-pos,
                      $m-switch-icon-font-size
      );
    }

    //== Form group
    .m-form__group.row & {
      margin-top: 0.15rem;

      &.m-switch--lg {
        margin-top: 0rem;
        position: relative;
        top: -0.3rem;
      }

      &.m-switch--sm {
        margin-top: 0.3rem;
      }
    }
  }
}

//== Component Size
@mixin m-build-component--switch-size($margin, $width, $height, $line-height, $space, $checked-tick-pos, $icon-font-size) {
  input:empty ~ span {
    line-height: $height;
    margin: $margin;
    height: $height;
    width: $width + $space;
    @include border-radius($height/2);
  }

  input:empty ~ span:before,
  input:empty ~ span:after {
    width: $width;
    @include border-radius($height/2);
  }

  input:empty ~ span:after {
    height: $height - 2 * $space;
    width: $height - 2 * $space;
    line-height: $line-height;
    top: $space;
    bottom: $space;
    margin-left: $space;
    font-size: $icon-font-size;
    text-align: center;
    vertical-align: middle;
  }

  input:checked ~ span:after {
    margin-left: $checked-tick-pos;
  }
}

//== Component Skin
@mixin m-build-component--switch-skin() {
  $skin: get-default-skin();

  .m-switch {
    //== Panel bg
    input:empty ~ span:before {
      background-color: get-color($skin, panel);
    }

    //== Tick bg
    input:empty ~ span:after {
      color: get-color($skin, panel, '--');
      background-color: get-color($skin, panel, '++');
    }

    //== Checked state
    input:checked {
      //== Panel bg
      ~ span:before {
        background-color: get-color($skin, panel);
        @include shadow(0px 3px 20px 0px rgba(get-color($skin, panel), 0.41));
      }

      //== Tick bg
      ~ span:after {
        background-color: get-brand-color();
        color: get-brand-inverse-color();
        @include shadow(0px 3px 20px 0px rgba(get-brand-color(), 0.41));
      }
    }

    input[disabled] {
      cursor: not-allowed;

      ~ span:after,
      ~ span:before {
        cursor: not-allowed;
        @include opacity(0.7);
      }
    }

    //== States
    @each $name, $color in $m--state-colors {
      //== states
      &.m-switch--#{$name}:not(.m-switch--outline) {
        input:empty ~ span:before {
          background-color: array-get($color, base);
        }

        input:empty ~ span:after {
          color: array-get($color, base);
          background-color: array-get($color, inverse);
          @include opacity(0.4);
        }

        input:checked {
          //== Panel bg
          ~ span:before {
            background-color: array-get($color, base);
          }

          //== Tick bg
          ~ span:after {
            @include opacity(1);
          }
        }
      }

      //== outline style states
      &.m-switch--outline.m-switch--#{$name} {
        input:empty ~ span:before {
          border: 2px solid get-color($skin, panel);
          background-color: get-color($skin, box);
        }

        input:empty ~ span:after {
          color: array-get($color, inverse);
          background-color: array-get($color, panel);
        }

        input:checked {
          //== Panel bg
          ~ span:before {
            background-color: array-get($color, inverse);
          }

          //== Tick bg
          ~ span:after {
            background-color: array-get($color, base);
            @include opacity(1);
          }
        }
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--switch-base();

//== Component Skin
@include m-build-component--switch-skin();
