//
//** Progress Customization
// 

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--progress-base() {
  //== Base
  .progress {
    .progress-bar {
      @include transition(all 0.5s ease);
    }

    &.m-progress--sm {
      height: 6px;

      .progress-bar {

        @include border-radius(3px);
      }
    }

    &.m-progress--lg {
      height: 20px;

      .progress-bar {
        @include border-radius(4px);
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--progress-base();
