//
//** User Profile Card
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--card-profile-base() {
  .m-card-profile {
    padding: 5px 0;
    margin: 0;

    .m-card-profile__pic {
      text-align: center;
      padding: 0 5px 0 0;
      text-align: center;

      .m-card-profile__pic-wrapper {
        padding: 8px;
        display: inline-block;
        margin: 2rem auto;
        @include border-radius(100%);
      }

      img {
        text-align: center;
        max-width: 130px !important;
        height: 130px;
        margin: 0 auto !important;
        @include border-radius(100%);
      }

      .m-card-profile__no-photo {
        height: 130px;
        width: 130px;
        display: inline-block;
        text-align: center;
        position: relative;
        @include vertical-horizontal-center-flexbox();
        @include border-radius(100%);

        > span {
          line-height: 0;
          font-weight: get-font-weight(boldest);
          font-size: get-font-size(regular, '+++');
          text-transform: uppercase;
        }
      }
    }

    .m-card-profile__title {
      text-align: center;
      font-size: get-font-size(regular, '++++');
      font-weight: get-font-weight(bolder);
    }

    .m-card-profile__details {
      text-align: center;
      padding: 0 0 0 0;

      // name
      .m-card-profile__name {
        display: block;
        padding: 0 0 0 0;
        font-size: get-font-size(regular, '++++');
        font-weight: get-font-weight(bolder);
      }

      // email
      .m-card-profile__email {
        display: inline-block;
        padding: 6px 0 0 0;
        font-size: get-font-size(regular);
      }
    }
  }
}

//== Component Skin
@mixin m-build-component--card-profile-skin($skin) {
  @include component-skin(m-card-profile, $skin) {
    .m-card-profile__pic {
      .m-card-profile__pic-wrapper {
        border: 2px solid get-color($skin, panel, '-');
      }
    }

    .m-card-profile__details {
      // name
      .m-card-profile__name {
        color: get-color($skin, regular, '++++');
      }

      // email
      .m-card-profile__email {
        @include m-set-component--typography-link-color(get-color($skin, regular, '--'), get-color($skin, regular, '-'));
      }
    }
  }
}

//==== Generate Component ====//

//== Component Base
@include m-build-component--card-profile-base();

//== Component Skin - Light
@include m-build-component--card-profile-skin(light);

//== Component Skin - Dark
@include m-build-component--card-profile-skin(dark); 
