//
//** Fullcalendar Component
//

//== Mixins
@mixin m-build-component--fullcalendar-base() {
  $cell-space-x: 0.5rem;
  $cell-space-y: 0.5rem;
  $head-space-x: 0.5rem;
  $head-space-y: 0.75rem;
  $head-list-space-x: 1.25rem;

  .fc-unthemed {
    // day
    .fc-day-grid td:not(.fc-axis) {
      padding: $cell-space-y $cell-space-x;

      &.fc-event-container {
        padding: 0.2rem $cell-space-x;
      }
    }

    .fc-axis {
      padding-top: $cell-space-y;
      padding-bottom: $cell-space-y;
    }

    .fc-scroller {
      .fc-content-col {
        padding: $cell-space-y $cell-space-x;
      }
    }

    // header
    th.fc-day-header {
      padding: $head-space-y $head-space-x;
      font-size: get-font-size(regular);
      font-weight: get-font-weight(bolder);
    }

    .fc-list-heading {
      .fc-widget-header {
        padding: $head-space-y $head-list-space-x;
      }

      .fc-list-heading-main,
      .fc-list-heading-alt {
        font-size: get-font-size(regular);
        font-weight: get-font-weight(bold);
      }

      .fc-list-heading-main {
        font-weight: get-font-weight(bolder);
        text-transform: uppercase;
      }
    }

    .fc-list-item {
      td {
        padding: $head-space-y $head-list-space-x;
      }

      .fc-event-dot {
        @include border-radius(50%);
      }
    }

    // past
    td.fc-past {

    }

    // today
    td.fc-today {

    }

    // future
    td.fc-future {

    }

    // event
    .fc-event,
    .fc-event-dot {
      padding: 0;

      .fc-content {
        padding: 0.55rem 0.55rem 0.55rem 2rem;

        &:before {
          display: block;
          content: " ";
          position: absolute;
          height: 10px;
          width: 10px;
          @include border-radius(50%);
          top: 0.7rem;
          left: 0.75rem;
        }
      }

      &.fc-not-start.fc-end {
        .fc-content {
          padding-left: 0.5rem;
        }
      }

      .fc-time {
        font-size: get-font-size(regular, '-');
        text-transform: uppercase;
        font-weight: get-font-weight(boldest);
      }

      .fc-title {
        font-size: get-font-size(regular, '-');
        font-weight: get-font-weight(bold);
      }

      @include rounded {
        @include border-radius(array-get($m--border-radius, general));
      }
    }

    .fc-time-grid-event {
      margin-top: -0.5rem;
    }

    //
    .fc-description {
      font-size: get-font-size(regular, '-');
      margin-top: 0.25rem;
      font-weight: get-font-weight(normal);
    }

    .fc-list-item-title > a {
      font-size: get-font-size(regular);
      font-weight: get-font-weight(bolder);
    }

    a.fc-more {
      font-size: get-font-size(regular, '-');
      font-weight: get-font-weight(bolder);
    }

    // popover
    .fc-popover {

      @include rounded {
        @include border-radius(array-get($m--border-radius, dropdown));
      }

      .fc-header {
        padding: 0.75rem 1.25rem;

        @include rounded {
          @include border-top-left-radius(array-get($m--border-radius, dropdown));
          @include border-top-right-radius(array-get($m--border-radius, dropdown));
        }

        .fc-close {
          margin-top: 0.35rem;
        }

        .fc-title {
          font-weight: get-font-weight(bold);
        }
      }

      .fc-body {
        .fc-event-container {
          padding: 1.25rem 1.25rem 0.75rem 1.25rem;
        }

        .fc-event {
          margin-bottom: 0.5rem;
          @include border-radius(array-get($m--border-radius, dropdown));

          &.fc-not-start.fc-not-end {
            @include border-top-right-radius(0);
            @include border-bottom-right-radius(0);
          }

          &.fc-not-start.fc-end {
            @include border-top-left-radius(0);
            @include border-bottom-left-radius(0);
          }
        }
      }
    }

    // toolbar
    .fc-toolbar {
      margin-bottom: 1.5rem;

      h2 {
        font-size: get-font-size(regular, '++');
        font-weight: get-font-weight(bolder);
        text-transform: uppercase;
        margin-top: 0.75rem;
      }

      .fc-button {
        outline: none !important;
        height: 2.75rem;
        padding: 0 1.25rem;
        font-size: get-font-size(regular);

        &.fc-corner-left {
          @include rounded {
            @include border-top-left-radius(array-get($m--border-radius, general));
            @include border-bottom-left-radius(array-get($m--border-radius, general));
          }
        }

        &.fc-corner-right {
          @include rounded {
            @include border-top-right-radius(array-get($m--border-radius, general));
            @include border-bottom-right-radius(array-get($m--border-radius, general));
          }
        }

        .fc-icon {
          font-size: 1.1rem;

          &:after {
            display: none;
          }

          &.fc-icon-left-single-arrow {
            @include la-icon('\f111');
          }

          &.fc-icon-right-single-arrow {
            @include la-icon('\f112');
          }
        }
      }
    }

    // time
    .fc-axis {
      > span {
        font-size: 0.9rem;
      }
    }
  }

  .ui-draggable-handle {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  @include tablet-and-mobile {
    .fc-unthemed {
      // toolbar
      .fc-toolbar {
        margin-bottom: 1.5rem;

        .fc-left,
        .fc-right,
        .fc-center {
          display: block;
          float: none;
          margin-bottom: 1rem;
          text-align: center;

          h2 {
            text-align: center;
            float: none;
          }

          > .fc-button-group {
            display: inline-block;
            float: none;

            > .fc-button {
              float: none;
            }
          }

          > .fc-button {
            float: none;
          }
        }
      }
    }
  }
}

@mixin m-build-component--fullcalendar-skin() {
  $skin: get-default-skin();
  $border-width: 6px;

  @include component-skin(fc-unthemed, $skin) {
    // general elements

    th, td, thead, tbody, .fc-divider, .fc-row, .fc-content, .fc-popover, .fc-list-view, .fc-list-heading td {
      border-color: get-color($skin, panel);
    }

    // past
    td.fc-past {

    }

    // today
    td.fc-today {
      background: lighten(get-brand-color(), 37%);
    }

    // future
    td.fc-future {

    }

    // event
    .fc-day-grid-event {
      margin: 0;
    }

    .fc-event,
    .fc-event-dot {
      background: #fff;
      border: 1px solid get-color($skin, panel);

      &.fc-not-start.fc-not-end {
        border-left: 1px solid get-color($skin, panel);
      }

      &.fc-start {
        .fc-content:before {
          background: get-color($skin, panel, '++++');
        }
      }

      @include shadow(get-shadow($skin, '--'));

      .fc-time {
        color: get-color($skin, regular, '++');
      }

      .fc-title {
        color: get-color($skin, regular, '+');
      }
    }

    .fc-description {
      color: get-color($skin, regular, '--');
    }

    .fc-list-item-title > a[href] {
      &:hover {
        color: get-brand-color();
        text-decoration: none;
      }
    }

    .fc-more {
      color: get-color($skin, regular, '+');
    }

    // event states
    .fc-event,
    .fc-event-dot {
      @each $name, $color in $m--state-colors {
        &.fc-start.m-fc-event--#{$name} {
          .fc-content {
            &:before {
              background: array-get($color, base);
            }
          }
        }

        &.fc-not-start.fc-end.m-fc-event--solid-#{$name},
        &.fc-not-start.fc-not-end.m-fc-event--solid-#{$name},
        &.fc-start.m-fc-event--solid-#{$name} {
          background: array-get($color, base);

          .fc-title {
            color: array-get($color, inverse);
          }

          .fc-description {
            color: array-get($color, inverse);
          }

          .fc-time {
            color: array-get($color, inverse);
          }
        }
      }
    }

    .fc-divider,
    .fc-popover .fc-header,
    .fc-list-heading td {
      background: get-color($skin, panel);
    }

    // popover
    .fc-popover {
      @include shadow(get-shadow($skin));
      background: #fff;

      .fc-header {
        background: #fff;
        border-bottom: 1px solid get-color($skin, panel, '--');

        .fc-close {
          color: get-color($skin, icon);

          &:hover {
            color: get-color($skin, icon, '+++')
          }
        }

        .fc-title {
          color: get-color($skin, regular);
        }
      }

      .fc-body {
        .fc-event,
        .fc-event-dot {
          &.fc-not-start.fc-not-end {
            .fc-content:before {
              background: get-color($skin, panel, '++++');
            }
          }

          @each $name, $color in $m--state-colors {
            &.fc-not-start.fc-not-end.m-fc-event--#{$name} {
              .fc-content:before {
                background: array-get($color, base);
              }
            }

            &.fc-not-start.fc-not-end.m-fc-event--solid-#{$name} {
              background: array-get($color, base);

              .fc-title {
                color: array-get($color, inverse);
              }

              .fc-description {
                color: array-get($color, inverse);
              }

              .fc-time {
                color: array-get($color, inverse);
              }
            }
          }
        }
      }
    }

    // header
    th.fc-day-header {
      > a,
      > span {
        color: get-color($skin, regular, '---');
      }
    }

    // list heading
    .fc-list-heading {
      td {
        background: get-color($skin, panel, '-');
        border-color: get-color($skin, panel, '-');
      }

      .fc-list-heading-main {
        color: get-color($skin, regular);
      }

      .fc-list-heading-alt {
        color: get-color($skin, regular, '--');
      }
    }

    // list item
    .fc-list-item {
      &:hover {
        td {
          background: transparent !important;
        }
      }

      .fc-event-dot {
        background: get-color($skin, panel, '++++');
        border-color: get-color($skin, panel, '++++');
      }

      @each $name, $color in $m--state-colors {
        &.m-fc-event--solid-#{$name},
        &.m-fc-event--#{$name} {
          .fc-event-dot {
            background: array-get($color, base);
            border-color: array-get($color, base);
          }
        }
      }
    }

    // toolbar
    .fc-toolbar {
      h2 {

      }

      .fc-button {
        color: get-color($skin, general, '-');
        background: get-color($skin, panel, '-');
        border: 0;
        text-shadow: none !important;

        .fc-icon {
          color: get-color($skin, general, '++');
        }

        &:hover {
          border: 0;
          background: get-color($skin, panel, '+');
          color: get-color($skin, general, '++');
        }

        &:focus,
        &:active,
        &.fc-state-active {
          border: 0;
          background: get-brand-color();
          color: get-brand-inverse-color();
          @include shadow(none);
          text-shadow: none;

          .fc-icon {
            color: get-brand-inverse-color();
          }
        }

        &.fc-state-disabled {
          background: get-color($skin, panel, '--');
          color: get-color($skin, muted);
        }
      }
    }
  }
}

//== Build
@include m-build-component--fullcalendar-base();
@include m-build-component--fullcalendar-skin();
