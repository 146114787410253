#receiving-stats {
  text-align: center;
  margin-bottom: 1em;

  .m-widget24__title {
    margin: 1em auto;
    text-align: center;
  }

  .m-widget24__number {
    margin: 0 auto;
    float: none;
    font-size: 3em;
    text-align: center;
    width: 100%;
  }
}
