//
//** Button Customization
// 

@each $name, $color in $m--state-colors {
  .btn-#{$name} {
    @include button-variant(array-get($color, base), array-get($color, base));
  }

  .btn-outline-#{$name} {
    @include button-outline-variant(array-get($color, base), array-get($color, inverse));
  }
}
