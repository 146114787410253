//
//** Container
//


//== Base markup
.m-container {
  @include clearfix();
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  // fluid container mode
  &.m-container--fluid {
    width: 100%;
  }

  // full height mode
  &.m-container--full-height {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
  }
}

//== Responsive container widths
@each $breakpoint, $breakpoint-width in $m--layout-breakpoints {
  @media (min-width: $breakpoint-width) {
    .m-container:not(.m-container--fluid):not(.m-container--responsive) {
      width: map-get($m--container-widths, $breakpoint);
    }
  }
}

@each $breakpoint, $breakpoint-width in $m--layout-breakpoints {
  @media (min-width: $breakpoint-width) {
    .m-container.m-container--responsive.m-container--#{$breakpoint} {
      width: map-get($m--container-widths, $breakpoint);
    }
  }
}
