//
//** Widget 7 Component
//

//== Component Base
@mixin m-build-component--widget7-base() {
  .m-widget7 {

    .m-widget7__desc {
      text-align: center;
      margin-top: 7rem;
      font-size: get-font-size(regular, '+++');
      font-weight: get-font-weight(regular);
    }

    .m-widget7__user {
      margin: 0 auto;
      display: table;
      margin-top: 0.35rem;
      margin-bottom: 5rem;

      .m-widget7__user-img {

        .m-widget7__img {
          width: 2.6rem;
          border-radius: 50%;
          margin-top: 1.14rem;
        }
      }

      .m-widget7__info {
        display: table-cell;

        .m-widget7__username {
          margin-top: 0.71rem;
          margin-left: 0.71rem;
          font-size: get-font-size(regular);
          font-weight: get-font-weight(bolder);
        }

        .m-widget7__time {
          margin-left: 0.71rem;
          margin-top: 0.71rem;
          font-size: get-font-size(regular, '-');
          font-weight: get-font-weight(regular);
        }
      }
    }

    .m-widget7__button {
      text-align: center;
      margin-bottom: 2.85rem;
    }
  }
}

//== Component Skin
@mixin m-build-component--widget7-skin($skin) {
  @include component-skin(m-widget7, $skin) {
    .m-widget7__desc {
      color: get-color($skin, regular);
    }
    .m-widget7__info {

      .m-widget7__username {
        color: get-color($skin, regular);
      }

      .m-widget7__time {
        color: get-color($skin, regular);
      }
    }

  }
}

//== Build Component Base
@include m-build-component--widget7-base();

//== Build Component - Light Skin
@include m-build-component--widget7-skin(light);

//== Build Component - Dark Skin
@include m-build-component--widget7-skin(dark);
