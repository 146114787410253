//
//** Member Success Component
//

//== Component Base
@mixin m-build-component--widget12-base() {
  // Widget
  .m-widget12 {
    // Item
    .m-widget12__item {
      display: table;
      width: 100%;
      margin-bottom: 2.85rem;

      &:last-child {
        margin-bottom: 0;
      }

      .m-widget12__text1 {
        display: table-cell;
        padding-right: 0.36rem;
        font-size: get-font-size(regular, '+');
        font-weight: get-font-weight(regular);

        > span {
          margin-top: 0.43rem;
          display: inline-block;
          font-size: get-font-size(regular, '++++');
          font-weight: get-font-weight(boldest);
        }
      }

      .m-widget12__text2 {
        display: table-cell;
        width: 45%;
        font-size: get-font-size(regular, '+');
        font-weight: get-font-weight(regular);

        > span {
          margin-top: 0.43rem;
          display: inline-block;
          color: red;
          font-size: get-font-size(regular, '++++');
          font-weight: get-font-weight(boldest);
        }

        .m-widget12__progress {
          display: table;
          width: 100%;

          .m-widget12__progress-sm {
            width: 90%;
            height: 0.5rem;

            .m-widget12__progress-bar {
              width: 63%;
              padding-bottom: 0.43rem;
            }
          }

          .m-widget12__stats {
            display: table-cell;
            width: 40%;
            line-height: 0;
            padding-top: 0.3rem;
            font-size: get-font-size(regular, '++++');
            font-weight: get-font-weight(boldest);
          }
        }
      }
    }

    .m-widget12__chart {
      position: relative;
    }

    &.m-widget12--chart-bottom {
      .m-widget12__chart {
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }
  }
}

//== Component Skin
@mixin m-build-component--widget12-skin() {
  $skin: get-default-skin();
  // Widget
  .m-widget12 {
    .m-widget12__item {
      .m-widget12__text1 {
        color: get-color($skin, regular, '-');

        > span {

        }
      }

      .m-widget12__text2 {
        color: get-color($skin, regular, '--');

        > span {
          color: get-color($skin, regular, '--');
        }

        .m-widget12__desc {
          color: get-color($skin, regular, '--');
        }

        .m-widget12__progress {

          .m-widget12__stats {
            color: get-color($skin, regular, '-');
          }
        }
      }
    }
  }
}

//== Build Component Base
@include m-build-component--widget12-base();

//== Build Component - Light Skin
@include m-build-component--widget12-skin();
