//
//** Body
// Page body container

//== General Mode
.m-body {
  .m-header--static & {
    //height: calc(100% - #{array-get($m-config-header, header, default, height, desktop)});
  }

  .m-content {
    padding: array-get($m-config-base, body, content, padding, desktop, y) array-get($m-config-base, body, content, padding, desktop, x);
  }

  background-color: array-get($m-config-base, body, content, bg-color);
}

body {
  background-color: array-get($m-config-base, body, content, bg-color);
}

//== Desktop Mode
@include desktop {
  .m-body {
    // fixed header
    .m-header--fixed & {
      padding-top: array-get($m-config-header, header, default, height, desktop) !important;
    }

    //== Fix fluid flex box item hor overflow issue
    .m-wrapper {
      min-width: 0%;
      width: 100%;
    }
  }
}

// Tablet & Mobile Mode
@include tablet-and-mobile {
  .m-body {
    // fixed header
    .m-header--fixed-mobile & {
      @include transition(padding-top array-get($m-config-header, header, fixed, transition));
      padding-top: array-get($m-config-header, header, default, height, mobile) !important;
    }

    // fixed and minimized header
    .m-header--fixed-mobile.m-header--minimize & {
      padding-top: array-get($m-config-header, header, fixed, minimize, height, mobile);
    }

    .m-content {
      padding: array-get($m-config-base, body, content, padding, mobile, y) array-get($m-config-base, body, content, padding, mobile, x);

      .m-aside-right--enabled & {
        padding-bottom: 0;
      }
    }
  }
}