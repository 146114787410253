//
//** Stack Layout
//

.m-stack {
  display: block;
  width: 100%;
  height: 100%;

  &.m-stack--hor {
    > .m-stack__item {
      display: block;

      &.m-stack__item--fluid {
        display: block;
        height: auto;
      }
    }
  }

  &.m-stack--ver {
    > .m-stack__item {
      display: block;
      height: auto;
    }
  }
}

@mixin m--build-stack-flex($mode) {
  .m-stack.m-stack--flex-#{$mode} {
    display: flex;
    flex-direction: column;

    &.m-stack--ver {
      > .m-stack__item.m-stack__item--first {
        display: block;
        order: 1;
      }

      > .m-stack__item.m-stack__item--last {
        display: block;
        order: 2;
      }
    }
  }
}

@mixin m--build-stack($mode) {
  .m-stack.m-stack--#{$mode} {
    display: table;
    table-layout: fixed;

    &.m-stack--fluid {
      table-layout: auto;
    }

    &.m-stack--inline {
      width: auto;
    }

    &.m-stack--hor {
      > .m-stack__item {
        display: table-row;
        vertical-align: top;
        height: 1px;

        > .m-stack__demo-item {
          height: 100%;
        }

        &.m-stack__item--fluid {
          height: auto;
        }

        &.m-stack__item--fit {
          line-height: 0;
        }

        &.m-stack__item--left {
          text-align: left;
        }

        &.m-stack__item--right {
          text-align: right;
        }

        &.m-stack__item--center {
          text-align: center;
        }

        &.m-stack__item--top {
          vertical-align: top;
        }

        &.m-stack__item--middle {
          vertical-align: middle;
        }

        &.m-stack__item--bottom {
          vertical-align: bottom;
        }
      }
    }

    &.m-stack--ver {
      &.m-stack--demo {
        height: 80px;
      }

      > .m-stack__item {
        display: table-cell;
        vertical-align: top;
        height: 100%;

        &.m-stack__item--fluid {
          width: 100%;
        }

        &.m-stack__item--fit {
          line-height: 0;
        }

        &.m-stack__item--left {
          text-align: left;
        }

        &.m-stack__item--right {
          text-align: right;
        }

        &.m-stack__item--center {
          text-align: center;
        }

        &.m-stack__item--top {
          vertical-align: top;
        }

        &.m-stack__item--middle {
          vertical-align: middle;
        }

        &.m-stack__item--bottom {
          vertical-align: bottom;
        }
      }
    }
  }
}

//== General Mode
@include m--build-stack(general);
@include m--build-stack-flex(general);

//== Desktop Mode
@include desktop {
  @include m--build-stack(desktop);
  @include m--build-stack-flex(desktop);
}

//== Desktop & Tablet Modes
@include desktop-and-tablet {
  @include m--build-stack(desktop-and-tablet);
  @include m--build-stack-flex(desktop-and-tablet);
}

//== Tablet Mode
@include tablet {
  @include m--build-stack(tablet);
  @include m--build-stack-flex(tablet);
}

//== Tablet & Mobile Modes
@include tablet-and-mobile {
  @include m--build-stack(tablet-and-mobile);
  @include m--build-stack-flex(tablet-and-mobile);
}

//== Mobile Mode
@include mobile {
  @include m--build-stack(mobile);
  @include m--build-stack-flex(mobile);
}

//== Demo
.m-stack--demo {
  &.m-stack--ver .m-stack__item,
  &.m-stack--hor .m-stack__demo-item {
    padding: 10px;
    border: 1px solid #eee;
    background: lighten(#eee, 5%);
    font-weight: get-font-weight(bolder);
  }
}
