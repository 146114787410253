//
//** Bootstrap Maxlength Component
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--maxlength-base() {
  .bootstrap-maxlength {
    z-index: 1040 !important;

    .modal & {
      z-index: 1060 !important;
    }
  }
}


//==== Generate Component ====//

//== Component Base
@include m-build-component--maxlength-base();
