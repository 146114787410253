//
//** Link Component
//

//== Component Base
@mixin m-build-component--typography-link-base() {
  .m-link {
    text-decoration: none;
    position: relative;
    display: inline-block;

    &:after {
      display: block;
      content: '';
      position: absolute;
      bottom: 0;
      top: 1rem;
      left: 0;
      width: 0%;
      @include transition(width 0.3s ease);
    }

    &:hover {
      text-decoration: none !important;

      &:after {
        width: 100%;
      }
    }
  }
}

@mixin m-build-component--typography-link-state() {
  @each $name, $color in $m--state-colors {
    .m-link.m-link--#{$name} {
      @include m-set-component--typography-link-color(array-get($color, base), darken(array-get($color, base), 10%));
    }
  }
}

@mixin m-set-component--typography-link-color($default, $hover) {
  color: $default;

  &:hover {
    color: $hover;

    &:after {
      border-bottom: 1px solid $hover;
      @include opacity(0.3);
    }
  }
}

//== Component Skin
@mixin m-build-component--typography-link-skin($skin) {
  @include component-skin(m-link, $skin) {
    @include m-set-component--typography-link-color(array-get($m--link-font, default, color, $skin), array-get($m--link-font, hover, color, $skin));
  }
}

//== Build Component Base
@include m-build-component--typography-link-base();

//== Build Component - Light Skin
@include m-build-component--typography-link-skin(light);

//== Build Component - Dark Skin
@include m-build-component--typography-link-skin(dark);

//== Build Component - State Colors
@include m-build-component--typography-link-state();
