//
//** Quick Sidebar Config
//

$m--quick-sidebar: (
        layout: (
          // self
                self: (
                  // base
                        padding: 20px 30px 30px 30px,
                  // offcanvas
                        offcanvas: (
                                zindex: 1001,
                                direction: right,
                                transition: all .3s ease,
                                width: 455px,
                        )
                ),
          // scrollbar
                scrollbar: (
                        right: -13px
                ),
          // close icon
                close: (
                        font-size: 1.4rem
                ),
          // tabbed
                tabbed: (
                        tabs: (
                                self: (
                                        margin: 0 0 30px 0
                                ),
                                item: (
                                        link: (
                                                padding: 15px 0
                                        )
                                )
                        ),
                        close: (
                                top: 16px,
                                right: 30px
                        )
                )
        ),
        skins: (
                light: (
                        self: (
                                bg-color: #ffffff,
                                box-shadow: 0px 4px 15px 1px rgba(get-brand-color(), 0.2)
                        )
                ),
                dark: (
                        self: (
                                bg-color: #ffffff,
                                box-shadow: 0px 4px 15px 1px rgba(get-brand-color(), 0.2)
                        )
                ),
        )
);
