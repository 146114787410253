//
//** "User Login - 2" Snippet
//

//== Snippet Base
@mixin m-build-snippet--login-2-base() {
  //== Login Base
  .m-login.m-login--2 {
    background-size: cover;
    background-repeat: no-repeat;

    //== Login Area
    .m-login__wrapper {
      padding: 6% 2rem 1rem 2rem;
      margin: 0 auto 2rem auto;
      overflow: hidden;

      .m-login__container {
        width: 430px;
        margin: 0 auto;

        .m-login__logo {
          text-align: center;
          margin: 0 auto 4rem auto;
        }

        .m-login__head {
          margin-top: 1rem;

          .m-login__title {
            text-align: center;
            font-size: 1.5rem;
          }

          .m-login__desc {
            margin-top: 1.5rem;
            text-align: center;
            font-size: font-get-size(regular);
          }
        }

        .m-login__form {
          margin: 4rem auto;

          .m-form__group {
            margin: 0;
            padding: 0;
            margin: 0 auto;

            .form-control {
              border-radius: 40px;
              border: none;
              padding: 1.5rem 1.5rem;
              margin-top: 1.5rem;

              &.m-login__form-input--last {
                border: 0;
              }
            }

            &.has-danger {
              .form-control {
                //color: get-state-color(danger);
                //@include input-placeholder(lighten(get-state-color(danger), 3%));
              }

              .form-control-feedback {
                font-weight: get-font-weight(bold);
                font-size: get-font-size(regular, '-');
                padding-left: 1.6rem;
              }
            }
          }

          .m-login__form-sub {
            padding-left: 7px;
            padding-right: 7px;
            margin: 15px auto;

            .m-checkbox {
              font-size: get-font-size(regular);
            }

            .m-link {
              font-size: get-font-size(regular);
            }
          }

          .m-login__form-action {
            text-align: center;
            margin-top: 7%;

            .m-login__btn {
              display: inline-block;
              text-align: center;
              padding: 1.4rem 4rem;
              margin-top: 0.8rem;
            }
          }
        }

        .m-login__account {
          text-align: center;
          margin-top: 5%;

          .m-login__account-msg {
            font-size: get-font-size(regular, '+');
            font-weight: get-font-weight(bold);
          }

          .m-login__account-link {
            font-size: get-font-size(regular, '+');
            font-weight: get-font-weight(bold);
          }
        }
      }
    }
  }

  //== Login Modes
  .m-login.m-login--2 {
    &.m-login--signin {
      .m-login__signup {
        display: none;
      }

      .m-login__signin {
        display: block;
      }

      .m-login__forget-password {
        display: none;
      }
    }

    &.m-login--signup {
      .m-login__signup {
        display: block;
      }

      .m-login__signin {
        display: none;
      }

      .m-login__forget-password {
        display: none;
      }

      .m-login__account {
        display: none;
      }
    }

    &.m-login--forget-password {
      .m-login__signup {
        display: none;
      }

      .m-login__signin {
        display: none;
      }

      .m-login__forget-password {
        display: block;
      }
    }

    @include tablet-and-mobile {
      .m-login__wrapper {
        padding-top: 3rem;

        .m-login__account {
          margin-top: 10rem;
        }
      }
    }

    @include mobile() {
      .m-login__wrapper {

        .m-login__container {
          width: 100%;
          margin: 0 auto;

          .m-login__form {
            width: 100%;
            margin: 0 auto;

            .m-login__form-sub {

              .m-login__form-left {
                padding-right: 0;
              }

              .m-login__form-right {
                padding-left: 0;
              }
            }

            .m-login__form-action {
              padding-top: 3rem;

              .btn {
                padding: 0.8rem 3rem;
                margin-top: 10px;
              }
            }
          }

          .m-login__form-action {
            .btn {
              padding: 1rem 3rem;
            }
          }

          .m-login__account {
            margin-top: 20%;
          }
        }
      }
    }
  }

  //== Tablet and mobile mode
  @include tablet-and-mobile {
    .m-login.m-login--2 {
      .m-login__wrapper {
        width: 100%;

        .m-login__container {
          .m-login__form {
            .m-login__form-action {
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  //== Mobile mode
  @include mobile {
    .m-login.m-login--2 {
      .m-login__wrapper {
        .m-login__container {
          .m-login__form {
            .m-login__form-sub {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

//== Snippet Skin - 1
@mixin m-build-snippet--login-2-skin-1() {
  $base-color: lighten(#b295f1, 5%);
  $base-input-bg-color: rgba(#4322A7, 0.4);
  $base-input-bg-color-mobile: rgba(#4322A7, 0.8);
  $base-title-color: #f9f5ff;

  .m-login.m-login--2.m-login-2--skin-1 {
    .m-login__container {
      .m-login__head {
        .m-login__title {
          color: $base-title-color;
        }

        .m-login__desc {
          color: $base-color;
        }
      }

      .m-login__form {
        color: $base-color;

        .form-control {
          color: lighten($base-color, 10%);
          background: $base-input-bg-color;
          @include input-placeholder(#b295f1);

          &:focus {
            color: lighten($base-color, 20%);
            @include input-placeholder(lighten($base-color, 10%));
          }
        }

        .m-login__form-sub {
          .m-checkbox {
            color: $base-color;
          }

          .m-link {
            @include m-set-component--typography-link-color($base-color, lighten($base-color, 20%));
          }
        }

        .m-login__form-action {
          .m-login__btn {
            color: darken($base-color, 5%);
            border-color: darken($base-color, 15%);
            background-color: transparent;

            &:focus,
            &:hover {
              border-color: lighten($base-color, 20%);
              color: lighten($base-color, 30%);
            }
          }

          .m-login__btn.m-login__btn--primary {
            color: lighten($base-color, 20%);
            border-color: $base-color;

            &:focus,
            &:hover {
              border-color: lighten($base-color, 20%);
              color: lighten($base-color, 30%);
            }
          }
        }
      }

      .m-login__account {
        .m-login__account-msg {
          color: $base-color;
        }

        .m-login__account-link {
          @include m-set-component--typography-link-color(lighten($base-color, 15%), lighten($base-color, 25%));
        }
      }
    }
  }

  @include tablet-and-mobile {
    .m-login.m-login--2.m-login-2--skin-1 {
      .m-login__container {
        .m-login__form {
          .form-control {
            background: $base-input-bg-color-mobile;
          }
        }
      }
    }
  }
}

//== Snippet Skin - 2
@mixin m-build-snippet--login-2-skin-2() {
  $base-color: darken(#b8b3c1, 5%);
  $base-input-bg-color: #f7f6f9;
  $base-title-color: darken(#b8b3c1, 10%);

  .m-login.m-login--2.m-login-2--skin-2 {
    .m-login__container {
      .m-login__head {
        .m-login__title {
          color: $base-title-color;
        }

        .m-login__desc {
          color: $base-color;
        }
      }

      .m-login__form {
        color: $base-color;

        .form-control {
          color: darken($base-color, 10%);
          background: $base-input-bg-color;
          @include input-placeholder(darken($base-color, 5%));

          &:focus {
            color: darken($base-color, 20%);
            @include input-placeholder(darken($base-color, 10%));
          }
        }

        .m-login__form-sub {
          .m-checkbox {
            color: $base-color;
          }

          .m-link {
            @include m-set-component--typography-link-color($base-color, darken($base-color, 20%));
          }
        }

        .m-login__form-action {
        }
      }

      .m-login__account {
        .m-login__account-msg {
          color: $base-color;
        }

        .m-login__account-link {
          @include m-set-component--typography-link-color(darken($base-color, 15%), darken($base-color, 25%));
        }
      }
    }
  }
}

//== Snippet Skin - 3
@mixin m-build-snippet--login-2-skin-3() {
  $base-color: lighten(#534878, 5%);
  $base-input-bg-color: #24143f;
  $base-title-color: lighten(#534878, 5%);

  .m-login.m-login--2.m-login-2--skin-3 {
    .m-login__container {
      .m-login__head {
        .m-login__title {
          color: $base-title-color;
        }

        .m-login__desc {
          color: $base-color;
        }
      }

      .m-login__form {
        color: $base-color;

        .form-control {
          color: lighten($base-color, 10%);
          background: $base-input-bg-color;
          @include input-placeholder(lighten($base-color, 5%));

          &:focus {
            color: lighten($base-color, 20%);
            @include input-placeholder(lighten($base-color, 10%));
          }
        }

        .m-login__form-sub {
          .m-checkbox {
            color: $base-color;
          }

          .m-link {
            @include m-set-component--typography-link-color($base-color, lighten($base-color, 20%));
          }
        }

        .m-login__form-action {
        }
      }

      .m-login__account {
        .m-login__account-msg {
          color: $base-color;
        }

        .m-login__account-link {
          @include m-set-component--typography-link-color(lighten($base-color, 15%), lighten($base-color, 25%));
        }
      }
    }
  }
}

//== Build Component Base
@include m-build-snippet--login-2-base();


//== Build Component Base
@include m-build-snippet--login-2-skin-1();

//== Build Component Base
@include m-build-snippet--login-2-skin-2();

//== Build Component Base
@include m-build-snippet--login-2-skin-3();
