//
//** jQVMap Plugin Customization
//
//

//== Mixins
@mixin m-build-component--jqvmap-base() {
  // Spinner markup
  .m-jqvmap {
    position: relative;
    overflow: hidden;

    .jqvmap-zoomout,
    .jqvmap-zoomin {
      height: 16px;
      width: 16px;
      line-height: 12px;
      vertical-align: middle;
      background-color: get-color(light, panel, '++++');
    }
  }
}

//== Build

@include m-build-component--jqvmap-base();
