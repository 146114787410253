//
//** Widget 16 Component
//

//== Component Base
@mixin m-build-component--widget16-base() {
  .m-widget16 {
    .m-widget16__head {
      .m-widget16__item {
        display: table;
        width: 100%;
        margin-bottom: 3px;

        .m-widget16__sceduled {
          display: table-cell;
          width: 33%;
          padding-left: 0;
          padding-right: 0;
          font-size: get-font-size(regular);
          font-weight: get-font-weight(bolder);
        }

        .m-widget16__amount {
          display: table-cell;
          width: 33%;
          padding-right: 0;
          padding-left: 0;
          font-size: get-font-size(regular);
          font-weight: get-font-weight(bolder);
        }
      }
    }

    .m-widget16__body {
      .m-widget16__item {
        display: table;
        width: 100%;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;

        .m-widget16__date {
          display: table-cell;
          width: 33%;
          padding-left: 0;
          padding-right: 0;
          vertical-align: top;
          font-size: get-font-size(regular);
          font-weight: get-font-weight(regular);
          padding-top: 0.2rem;
        }

        .m-widget16__price {
          display: table-cell;
          width: 33%;
          padding-right: 0;
          padding-left: 0;
          vertical-align: top;
          font-size: get-font-size(regular);
          font-weight: get-font-weight(bolder);
          padding-top: 0.2rem;
        }
      }
    }

    .m-widget16__stats {
      display: table;
      vertical-align: top;
      margin-top: 2rem;
      width: 100%;

      .m-widget16__visual {
        display: table-cell;
        width: 50%;
        vertical-align: middle;

        .m-widget16__chart {
          position: relative;
          margin-top: 0.5rem;

          .m-widget16__chart-number {
            font-size: 2.4rem;
            font-weight: get-font-weight(boldest);
            @include vertical-horizontal-center();
          }
        }
      }

      .m-widget16__legends {
        display: table-cell;
        width: 40%;
        vertical-align: middle;

        .m-widget16__legend {
          margin-bottom: 0.9rem;

          &:last-child {
            margin-bottom: 0;
          }

          .m-widget16__legend-bullet {
            width: 2rem;
            height: 0.45rem;
            display: inline-block;
            border-radius: 1.1rem;
            margin-bottom: 0.12rem;
            margin-right: 0.8rem;
          }

          .m-widget16__legend-text {
            display: inline-block;
          }
        }
      }
    }
  }
}

//== Component Skin
@mixin m-build-component--widget16-skin() {
  $skin: get-default-skin();

  .m-widget16 {
    .m-widget16__head {
      .m-widget16__item {
        .m-widget16__amount,
        .m-widget16__sceduled {
          color: darken(get-state-color(metal, base), 10%);
        }
      }
    }

    .m-widget16__body {
      .m-widget16__item {
        .m-widget16__date {
          color: get-color($skin, regular, '----');
        }

        .m-widget16__stats {
          color: get-color($skin, regular, '----');
        }

        border-bottom: 0.07rem dashed get-color($skin, panel);

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    .m-widget16__stats {
      .m-widget16__title {
        color: get-color($skin, regular);
      }

      .m-widget16__text {
        color: get-color($skin, regular, '----');
      }

      .m-widget16__legend-text {
        color: get-color($skin, regular, '----');
      }

      .m-widget16__chart-number {
        color: #a7a7c2;
      }
    }
  }
}

//== Build Component Base
@include m-build-component--widget16-base();

//== Build Component - Light Skin
@include m-build-component--widget16-skin();
