//
//** Timeline 3 Component
//

//== Component Base
@mixin m-build-component--timeline-3-base() {
  .m-timeline-3 {
    .m-timeline-3__item {
      disply: table;
      margin-bottom: 1rem;
      position: relative;

      // state bar
      &:before {
        position: absolute;
        display: block;
        width: 0.28rem;
        @include border-radius(0.3rem);
        height: 70%;
        left: 5.1rem;
        top: 0.46rem;
        content: "";
      }

      .m-timeline-3__item-time {
        display: table-cell;
        vertical-align: top;
        padding-top: 0.6rem;
        font-weight: get-font-weight(bolder);
        font-size: get-font-size(regular, '+++');
        position: absolute;
        text-align: right;
        width: 3.57rem;
      }

      // description text
      .m-timeline-3__item-desc {
        display: table-cell;
        width: 100%;
        vertical-align: top;
        font-size: get-font-size(regular);
        font-weight: get-font-weight(regular);
        padding-left: 7rem;

        .m-timeline-3__item-text {
          font-size: get-font-size(regular);
          font-weight: get-font-weight(bold);
        }

        .m-timeline-3__item-user-name {

          .m-timeline-3__item-link {
            font-size: get-font-size(regular, '-');
            font-weight: get-font-weight(regular);
            text-decoration: none;
          }
        }
      }
    }
  }

  @include mobile() {
    .m-timeline-3 {
      .m-timeline-3__item {
        margin-left: 0;
      }
    }
  }
}

//== Component Skin
@mixin m-build-component--timeline-3-skin() {
  $skin: get-default-skin();

  .m-timeline-3__item {
    .m-timeline-3__item-time {
      color: get-color($skin, regular, '---');
    }

    .m-timeline-3__item-desc {
      .m-timeline-3__item-text {
        color: get-color($skin, regular, '--');
      }
    }
  }

  .m-timeline-3__item {
    // states
    @each $name, $color in $m--state-colors {
      &.m-timeline-3__item--#{$name} {
        &:before {
          background: array-get($color, base);
        }
      }
    }
  }
}

//== Build Component Base
@include m-build-component--timeline-3-base();

//== Build Component - Light Skin
@include m-build-component--timeline-3-skin();


