//
//** Spinners Config
//

$m--spinners: (
  // loader
        loader: (
                default: (
                        size: 1.4rem,
                        width: 2px
                ),
                lg: (
                        size: 2rem,
                        width: 3px
                ),
                sm: (
                        size: 1rem,
                        width: 1px
                )
        ),
  // spinner
        spinner: (
                default: (
                        size: 1.5rem
                ),
                lg: (
                        size: 2rem
                ),
                sm: (
                        size: 1.2rem
                )
        )
);
